@font-face {
  font-family: "iconfont"; /* Project id 3707870 */
  src: url('iconfont.woff2?t=1699001514420') format('woff2'),
       url('iconfont.woff?t=1699001514420') format('woff'),
       url('iconfont.ttf?t=1699001514420') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-down-filling:before {
  content: "\e688";
}

.icon-arrow-right-filling:before {
  content: "\e689";
}

.icon-image1:before {
  content: "\e65b";
}

.icon-arrow-down:before {
  content: "\e607";
}

.icon-Up:before {
  content: "\e620";
}

.icon-detail:before {
  content: "\e76d";
}

.icon-android:before {
  content: "\e669";
}

.icon-ios-albums:before {
  content: "\e704";
}

.icon-attention:before {
  content: "\e600";
}

.icon-chevron-right:before {
  content: "\e610";
}

.icon-VIP:before {
  content: "\e69d";
}

.icon-wechat-pay:before {
  content: "\e60f";
}

.icon-flash-outline:before {
  content: "\e729";
}

.icon-cloud-upload-outline:before {
  content: "\e7d9";
}

.icon-image-outline:before {
  content: "\e9c9";
}

.icon-chatbubbles-outline:before {
  content: "\e823";
}

.icon-logout:before {
  content: "\e651";
}

.icon-scan:before {
  content: "\ea1e";
}

.icon-options:before {
  content: "\e82d";
}

.icon-trash-outline:before {
  content: "\e830";
}

.icon-chevron-back:before {
  content: "\e959";
}

.icon-ellipsis-horizontal:before {
  content: "\e95e";
}

.icon-aperture:before {
  content: "\e96b";
}

.icon-arrow-redo-circle-sharp:before {
  content: "\e96e";
}

.icon-arrow-undo-circle:before {
  content: "\e96f";
}

.icon-create-outline:before {
  content: "\e9a1";
}

.icon-fitness:before {
  content: "\e9ad";
}

.icon-flower:before {
  content: "\e9b0";
}

.icon-notifications-off-outline:before {
  content: "\e9f7";
}

.icon-pricetag-outline:before {
  content: "\ea0b";
}

.icon-share-outline:before {
  content: "\ea22";
}

.icon-trash:before {
  content: "\eccf";
}

.icon-link:before {
  content: "\e667";
}

.icon-bold:before {
  content: "\e703";
}

.icon-earth2:before {
  content: "\e6df";
}

.icon-chatbubbles:before {
  content: "\e714";
}

.icon-link1:before {
  content: "\e6e6";
}

.icon-search:before {
  content: "\e671";
}

.icon-wechat:before {
  content: "\e640";
}

.icon-listul:before {
  content: "\e768";
}

.icon-checksquareo:before {
  content: "\e685";
}

.icon-square-o:before {
  content: "\e71e";
}

.icon-image:before {
  content: "\e7de";
}

.icon-menu:before {
  content: "\e7f4";
}

.icon-weibo:before {
  content: "\e882";
}

.icon-help-circle:before {
  content: "\e60e";
}

.icon-ios-folder-open:before {
  content: "\e733";
}

.icon-send:before {
  content: "\e911";
}

.icon-tag:before {
  content: "\e920";
}

.icon-ios-pricetags:before {
  content: "\e794";
}

.icon-copy-outline:before {
  content: "\e65d";
}

.icon-notifications:before {
  content: "\e715";
}

.icon-bookmark:before {
  content: "\e679";
}

.icon-arrow-up-circle1:before {
  content: "\e87e";
}

.icon-arrow-down-circle:before {
  content: "\e87d";
}

.icon-chevron-down:before {
  content: "\e6a6";
}

.icon-tag1:before {
  content: "\e6d4";
}

.icon-at-circle-outline:before {
  content: "\e7d2";
}

.icon-bandage-outline:before {
  content: "\e7d3";
}

.icon-file-tray:before {
  content: "\e82a";
}

