body {
  margin: 0;
  font-family: "lucida grande", "lucida sans unicode", lucida, helvetica, "Hiragino Sans GB", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F8F9FA;
  /* white-space: pre; */
}

/* 美化滚动条 */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-track {
  width: 5px;
  background: rgba(#101F1C, 0.1);
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(144,147,153,.5);
  background-clip: padding-box;
  min-height: 28px;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
  transition: background-color .3s;
  cursor: pointer;
}
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(144,147,153,.3);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus {
  box-shadow: none;
  border-right-width: 0px;
}

.ql-container.ql-snow {
  border: 0px solid #ccc;
}

.ql-snow.ql-toolbar button, .ql-snow .ql-toolbar button {
  padding: 0px 5px;
}

.ql-snow.ql-toolbar button svg, .ql-snow .ql-toolbar button svg {
  height: 80%;
}

.ql-editor ol, .ql-editor ul {
  padding-left: 0em;
}

p {
  margin-bottom: 0em;
}

.ql-editor {
  line-height: 1.6;
  font-size: 14px;
}
.ql-editor ul li:not(.ql-direction-rtl) {
  padding-left: 1.3em;
}

.ql-editor li.ql-indent-1:not(.ql-direction-rtl) {
  margin-left: 0.7em;
  padding-left: 2em;
  /* border-left-width: 1px;
  border-left-color:lightgray;
  border-left-style: solid; */
}
.ql-editor li.ql-indent-2:not(.ql-direction-rtl) {
  margin-left: 0.7em;
  padding-left: 4em;
  /* border-left-width: 1px;
  border-left-color:lightgray;
  border-left-style: solid;
  &::before {
    width: 2em;
    border-left-width: 1px;
    border-left-color:lightgray;
    border-left-style: solid;
  } */
}
.ql-editor li.ql-indent-3:not(.ql-direction-rtl) {
  margin-left: 0.7em;
  padding-left: 6em;
  /* border-left-width: 1px;
  border-left-color:lightgray;
  border-left-style: solid;
  &::before {
    width: 4em;
    border-left-width: 1px;
    border-left-color:lightgray;
    border-left-style: solid;
  } */
}
.ql-editor li.ql-indent-4:not(.ql-direction-rtl) {
  margin-left: 0.7em;
  padding-left: 8em;
  /* border-left-width: 1px;
  border-left-color:lightgray;
  border-left-style: solid; */
}
.ql-editor li.ql-indent-5:not(.ql-direction-rtl) {
  margin-left: 0.7em;
  padding-left: 10em;
  /* border-left-width: 1px;
  border-left-color:lightgray;
  border-left-style: solid; */
}
.ql-editor li.ql-indent-6:not(.ql-direction-rtl) {
  margin-left: 0.7em;
  padding-left: 12em;
  /* border-left-width: 1px;
  border-left-color:lightgray;
  border-left-style: solid; */
}
.ql-editor li.ql-indent-7:not(.ql-direction-rtl) {
  margin-left: 0.7em;
  padding-left: 14em;
  /* border-left-width: 1px;
  border-left-color:lightgray;
  border-left-style: solid; */
}

/* .ql-editor li.ql-indent-2::before {
  width: 2em;
  border-left-width: 1px;
  border-left-color:lightgray;
  border-left-style: solid;
}
.ql-editor li.ql-indent-3::before {
  width: 3em;
  border-left-width: 1px;
  border-left-color:lightgray;
  border-left-style: solid;
}
.ql-editor li.ql-indent-3::before {
  width: 2em;
  border-left-width: 1px;
  border-left-color:lightgray;
  border-left-style: solid;
} */

img {
  max-width: 400px;
  max-height: 400px;
}

.note:hover { box-shadow: 0px 2px 16px #DDDDDD; }

.note img { border:1px solid #E6E6E6; border-radius: 4px; margin:6px 0; }

.ant-modal-body .ant-image-img { border:1px solid #E6E6E6; border-radius: 4px; margin:6px 0; }

.ant-modal-content { border:1px solid #E6E6E6; border-radius: 6px; }

.img-share img { max-width: 96%; border:1px solid #E6E6E6; border-radius: 4px; margin:6px 0; }

.search-text { background-color: coral; }

iframe {
  height: 240px;
}

.ant-modal-title {
  text-align: center;
}
.ant-popover-title {
  text-align: center;
}
.ant-btn-default {
  box-shadow: none;
}

.menu-button:hover { background-color: #FFFFFF; border-radius: 10px; }
.tag-button:hover { opacity: .7; }

.aicontent {
  overflow-wrap: anywhere;
  word-break: normal;
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / .1), 0 1px 2px -1px rgb(0 0 0 / .1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow);
  opacity: .8;
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: .75rem;
  padding-right: .75rem;
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
  border-top-right-radius: 0;
  border-radius: .5rem;
}

#loginPwd {
  background-color: #F7F7F9;
}

